* {
  box-sizing: border-box;
}



body {
  margin: 0;
  padding: 0;
}

.container {
  /* border: 1px solid black; */
  /* background-color: green; */
}

.container #badgeContainer {
  /* border: 1px solid black; */
  /* padding: 0 10px; */
}

.container #badgeContainer  #busBadge {
  /* border: 1px solid black; */
}

.container #badgeContainer  #trainBadge {
  /* border: 1px solid black; */
}

.location-icon {
  font-size: 1.8rem;
  color: #18447b;
}

.container nav {
  background-color: #263237;
  border: 1px solid transparent;
}

.container nav ul {
  list-style: none;
  font-family: 'Archivo Black', sans-serif;
}

.container nav ul li {
  display: inline-block;
}

.container nav ul li a {
  text-decoration: none;
  color: #fff;
}

.container #trainArrivalContainer .trainMap{
    height: 400px;
    width: 95%;
    padding-bottom: 10px;
    margin: 0 auto;
    display: block;
}

.container #trainArrivalContainer .trainMap #mapErrorMsg {
  font-family: 'Archivo Black', sans-serif;
  font-size: 1.7em;
}





/* PHONE STYLES */
@media screen and (max-width: 480px) {

  .container {
    height: 100vh;
  }

  .container nav .overlay {
    height: 100%;
    width: 0;
    position: fixed;
    /* z-index: 1; */
    z-index: 4;
    left: 0;
    top: 0;
    background-color: rgb(138, 183, 212);
    overflow-x: hidden;
    transition: 0.5s;
  }

  .container nav .overlay .overlay-content #topOverlay #titleContainer {
    /* display: inline-block; */
    /* float: left; */
  }

  .container nav .overlay .overlay-content #topOverlay #titleContainer h1 {
    text-align: center;
    font-family: Raleway;
    font-size: 1.6em;

  }

  .container nav .overlay .overlay-content #topOverlay #closeIconContainer {
    /* display: inline-block; */
    float: right;
    margin-right: 30px;
    margin-top: 1px;
  }

  


  .container nav .overlay .overlay-content #topOverlay #closeIconContainer .fa {
    font-size: 2em;
  }

  .container nav .overlay .overlay-content ul {
    color: black;
    padding-top: 20px;
    clear: both;
  }

  .container nav .overlay .overlay-content ul li #directionStyle {
    font-size: 1.6em;
  }

  


  .container nav .overlay .overlay-content #topOverlay #divider {
    height: 1px;
    background-color: #18447b;
    width: 90%; 
    margin: 0 auto;
  }

  .container nav .overlay .overlay-content #topOverlay #removeStopsContainer {
    margin: 20px auto 0 auto;
    text-align: center;
  }

 

  .container nav .overlay .overlay-content #topOverlay #removeStopsContainer #removeText {
    display: inline-block;
    font-family: Raleway;
    font-weight: bolder;
    font-size: 1.3em;

  }

  .container nav .overlay .overlay-content #topOverlay #removeStopsContainer #trashIcon {
    display: inline-block;
    padding-left: 9px;
 }

 .container nav .overlay .overlay-content #topOverlay #removeStopsContainer #trashIcon .fa {
   font-size: 1.4em;
 }

 /* .container nav .overlay .overlay-content #savedBusList {
  border: 1px solid black;
 } */

 .container nav .overlay .overlay-content #savedBusList li {
  margin-top: 5px;
  border-bottom: 1px solid black;
  padding-bottom: 6px;
 }

 .container nav .overlay .overlay-content #savedBusList li #directionStyle {
   padding-left: 24px;
 }

 .container nav .overlay .overlay-content #savedBusList li .fa.fa-times {
   float: right;
 }

 .container nav .overlay .overlay-content #savedTrainList li{
   width: 95%;
   border: 1px solid black;
   border-radius: 4px;
   margin-bottom: 9px;
   margin-right: 4px;
   
   padding: 4px;
   color: #fff;
   font-weight: 100;
 }

 .container nav .overlay .overlay-content #savedTrainList li .fa {
   float: right;
 }





  .container nav ul li {
    padding-right: 16px;
  }

  .container .headline {
    font-family: Raleway;
    font-size: 1.4em;
    text-align: center;
    color: '#18447b';
    margin: 0;
    padding-top: 7px;
  
  }

  .container #aboutContainer {
    font-family: Raleway;
    background: #94baf7;
  }

  .container #aboutTitle {
    text-align: center;
    font-size: 2em;
    padding-top: 30px;
  }

  .container #logo {
    padding-top: 30px;
  }

  .container #logo img {
    margin: auto;
  }

  .container #axiosLogo {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .container #axiosLogo img {
    margin: auto;
  }

  .container #myPhoto img {
    margin: auto;
  }

  .container #badgeContainer {
    display: block;
    /* background-color: #263237; */
    background-color: #94baf7;
    padding-top: 20px;
    padding-bottom: 40px;
    height: 100vh;
  }

  .container #badgeContainer  #busBadge {
    border: 1px solid transparent;
    width: 60%;
    margin: 10px auto 0 auto;
    border-radius: 4px;
    -webkit-box-shadow: 5px 5px 15px -2px #000000; 
    box-shadow: 5px 5px 15px -2px #000000;
    background-color: #fff;
  }

  .container #badgeContainer  #busBadge #busIcon {
    width: 40%;
    display: inline-block;
    padding-left: 8px;
    padding-top: 10px;
  }

  .container #badgeContainer  #busBadge #busIcon .fa {
    font-size: 4rem;
    display: block;
    margin: 0 auto 20px auto;
    color: #18447b;
  }

  .container #badgeContainer  #busBadge #busText {
    display: inline-block;
    width: 60%;
    vertical-align: top;
    padding-top: 40px;
    font-size: 1.2em;
    font-family: 'Raleway', sans-serif;
    font-weight: bolder;
    color: #333;

  }

  .container #badgeContainer  #trainBadge {
    border: 1px solid transparent;
    width: 60%;
    margin: 40px auto 0 auto;
    border-radius: 4px;
    -webkit-box-shadow: 5px 5px 15px -2px #000000; 
    box-shadow: 5px 5px 15px -2px #000000;
    background-color: #fff;
  }

  .container #badgeContainer  #trainBadge #trainIcon {
    width: 40%;
    display: inline-block;
    padding-left: 8px;
    padding-top: 10px;
  }

  .container #badgeContainer  #trainBadge #trainIcon .fa {
    font-size: 4rem;
    display: block;
    margin: 0 auto 20px auto;
    color: #263237;
  }

  .container #badgeContainer  #trainBadge #trainText {
    display: inline-block;
    width: 60%;
    vertical-align: top;
    padding-top: 40px;
    font-size: 1.1em;
    font-family: 'Raleway', sans-serif;
    font-weight: bolder;
    color: #333;
  }

  .container #badgeContainer #alertBadge {
    border: 1px solid transparent;
    width: 60%;
    margin: 40px auto 0 auto;
    border-radius: 4px;
    -webkit-box-shadow: 5px 5px 15px -2px #000000; 
    box-shadow: 5px 5px 15px -2px #000000;
    background-color: #fff;
  }

  .container #badgeContainer #alertBadge #alertIcon {
    width: 40%;
    display: inline-block;
    padding-left: 8px;
    padding-top: 10px;
  }

  .container #badgeContainer #alertBadge #alertIcon .fa {
    font-size: 4rem;
    display: block;
    margin: 0 auto 20px auto;
    color: #263237;
    color: red;
  }

  .container #badgeContainer #alertBadge #alertText {
    display: inline-block;
    width: 60%;
    vertical-align: top;
    padding-top: 30px;
    font-size: 1.2em;
    font-family: 'Raleway', sans-serif;
    font-weight: bolder;
    color: #333;
  }

  .container #busRouteContainer {
    padding: 15px 9px 9px 9px;
    background-color: #94baf7;
    
  }

  .container #busRouteContainer .busRouteItemContainer {
    border: 1px solid transparent;
    border-radius: 4px;
    font-family: 'Raleway', sans-serif;
    font-size: .9em;
    font-weight: bolder;
    width: 70%;
    padding: 9px;
    margin: 0 auto 19px auto;
    background-color: #fff;
    -webkit-box-shadow: 5px 5px 15px -11px #000000; 
    box-shadow: 5px 5px 15px -11px #000000;
  }

  .container .busRouteItemContainer .busRouteNumber {
    display: inline-block;
    width: 10%;
    text-align: center;
  }

  .container .busRouteItemContainer .busRouteName {
    display: inline-block;
    width: 90%;
  }

  .container #busStopContainer {
    background-color: #94baf7;
  }

  /* .container #busStopContainer h1 {
    font-family: Raleway;
    font-size: 1.4em;
    text-align: center;
    color: '#18447b';
    margin: 0;
    padding-top: 7px;
   
  } */

  .container #busStopContainer .busStopItem {
    border: 1px solid transparent;
    border-radius: 4px;
    font-family: 'Raleway', sans-serif;
    font-size: .9em;
    font-weight: bolder;
    width: 70%;
    padding: 9px;
    margin: 0 auto 19px auto;
    background-color: #fff;
    -webkit-box-shadow: 5px 5px 15px -11px #000000; 
    box-shadow: 5px 5px 15px -11px #000000;
  }

  .container #busArrivalContainer {
    padding-top: 16px;
    background-color: #94baf7;
    padding-bottom: 16px;
    /* height: 100vh; */
  }

  .container #busArrivalContainer .map {
    width: 95%;
    -webkit-box-shadow: 5px 5px 15px -11px #000000; 
    box-shadow: 5px 5px 15px -11px #000000;
    height: 400px;
    margin: auto;
  }

  .container #busArrivalContainer .busArrivalItem {
    font-family: 'Raleway', sans-serif;
    font-size: 1em;
    margin: 22px auto 19px auto;
    font-weight: bolder;
    padding-top: 17px;
    width: 80%;
    padding: 9px;
    background-color: #fff;
    -webkit-box-shadow: 5px 5px 15px -11px #000000; 
    box-shadow: 5px 5px 15px -11px #000000;



  }

  .container #busArrivalContainer .busArrivalItem #arrivalTime {
    text-align: center;
    font-size: 2em;
    margin-bottom: 5px;
  }

  .container #trainLinesContainer {
    border: 1px solid transparent;
    background-color: #94baf7;
    height: 100vh;
  }

  

  .container #trainLinesContainer .trainButton {
    width: 80%;
    padding: 9px;
    background-color: #fff;
    -webkit-box-shadow: 5px 5px 15px -11px #000000; 
    box-shadow: 5px 5px 15px -11px #000000;
    margin: 29px auto 23px auto;
    text-align: center;
    font-size: 1.3em;
    font-family: 'Raleway', sans-serif;
    border-radius: 8px;
    color: #fff;
  }

  .container #trainLinesContainer #blueLineContainer {
    background-color: blue;
  }

  .container #trainLinesContainer #redLineContainer {
    background-color: red;
  }

  .container #trainLinesContainer #brownLineContainer {
    background-color: brown;
  }

  .container #trainLinesContainer #greenLineContainer {
    background-color: green;
  }

  .container #trainLinesContainer #orangeLineContainer {
    background-color: darkorange;
  }

  .container #trainLinesContainer #purpleLineContainer {
    background-color: purple;
  }

  .container #trainLinesContainer #pinkLineContainer {
    background-color: hotpink;
  }

  .container #trainLinesContainer #yellowLineContainer {
    background-color: yellow;
  }

  .container #trainStopsContainer {
    border: 1px solid transparent;
  }

  .container #trainStopsContainer .stationName{
    width: 80%;
    padding: 9px;
    background-color: #fff;
    /* -webkit-box-shadow: 5px 5px 15px -11px #000000; 
    box-shadow: 5px 5px 15px -11px #000000; */
    margin: 29px auto 23px auto;
    text-align: center;
    font-size: 1.3em;
    font-weight: bolder;
    font-family: 'Raleway', sans-serif;
    border-radius: 8px;
    color: #333;
  }

  .container #trainDestinationContainer {
    border: 1px solid transparent;
    height: 100vh;
  }

  .container #trainDestinationContainer h2 {
    text-align: center;
    font-family: 'Raleway', sans-serif;
    
  }

  .container #trainDestinationContainer .trainDestinationItem {
    width: 80%;
    padding: 9px;
    background-color: #fff;
    /* -webkit-box-shadow: 5px 5px 15px -11px #000000; 
    box-shadow: 5px 5px 15px -11px #000000; */
    margin: 29px auto 23px auto;
    text-align: center;
    font-size: 1.3em;
    font-weight: bolder;
    font-family: 'Raleway', sans-serif;
    border-radius: 8px;
    color: #333;
  }

  .container #trainArrivalContainer {
    border: 1px solid transparent;
    /* height: 100vh; */
  }

  .container #trainArrivalContainer #refreshTrains {
    text-align: center;
    font-size: 1.9em;
  }

  .container #trainArrivalContainer .trainArrivalItem {
    width: 80%;
    padding: 9px;
    background-color: #fff;
    /* -webkit-box-shadow: 5px 5px 15px -11px #000000; 
    box-shadow: 5px 5px 15px -11px #000000; */
    margin: 29px auto 23px auto;
    /* text-align: center; */
    font-size: 1.1em;
    font-weight: bolder;
    font-family: 'Raleway', sans-serif;
    border-radius: 8px;
    color: #333;
    
  }

  .container #trainArrivalContainer .trainArrivalItem .destination {
    text-align: center;
    padding: 0 0 3px 0;
  }

  .container #trainArrivalContainer .trainArrivalItem .trainRun {
    padding-top: 7px;
    display: inline-block;
    width: 50%;
  }

  .container #trainArrivalContainer .trainArrivalItem .minutesToArrive {
    display: inline-block;
    width: 50%;
    font-size: 1.8em;
    padding-top: 3px;
  }

  .container #serviceBulletinContainer {
    padding-top: 40px;
    position: relative;
  }

  .container #serviceBulletinContainer #busAlertButton {
    border: 1px solid transparent;
    width: 70%;
    margin: 10px auto 0 auto;
    border-radius: 4px;
    -webkit-box-shadow: 5px 5px 15px -2px #000000; 
    box-shadow: 5px 5px 15px -2px #000000;
    background-color: #fff;
  }

  .container #serviceBulletinContainer  #busAlertButton #busAlertText {
    font-family: 'Raleway', sans-serif;
    font-size: 1.3em;
    text-align: center;
    text-transform: uppercase;
    color: blue;
    font-weight: bolder;
  }

  .container #serviceBulletinContainer #busAlertButton #busAlertText .fa {
    float: right;
    padding-right: 10px;
    font-size: 1.2em;
  }

  .container #serviceBulletinContainer #busAlertPanel {
    width: 70%;
    margin: 0 auto 0 auto;
    left: 15%;
    border-radius: 0 0 4px 4px;
    background-color: #fff;
    z-index: 3;
    position: absolute;
  }

  .container #serviceBulletinContainer #busAlertPanel .busAlertItem {
    border: 1px solid #333;
    font-family: 'Raleway', sans-serif;
    margin-bottom: 9px;
    border-radius: 4px;
    font-size: 1em;
  }


  .container #serviceBulletinContainer #busAlertPanel .busAlertItem #busAlertRoute {
    display: inline-block;
    width: 50%
  }

  .container #serviceBulletinContainer #busAlertPanel .busAlertItem #busAlertStatus {
    display: inline-block;
    width: 50%;
    color: red;
    text-transform: uppercase;
  }

  .container #serviceBulletinContainer #busAlertPanel .busAlertItem #alertDescription {
    display: inline-block;
    border: 1px solid black;
  }

  .container #serviceBulletinContainer #busAlertPanel .busAlertItem #closeDescription {
    display: inline-block;
    border: 1px solid black;

  }

  

  .container #serviceBulletinContainer #railAlertButton {
    border: 1px solid transparent;
    width: 70%;
    margin: 30px auto 0 auto;
    border-radius: 4px;
    -webkit-box-shadow: 5px 5px 15px -2px #000000; 
    box-shadow: 5px 5px 15px -2px #000000;
    background-color: #fff;
    z-index: 2;
    /* position: absolute; */
  }

  .container #serviceBulletinContainer  #railAlertButton #railAlertText {
    text-align: center;
    font-family: 'Raleway', sans-serif;
    font-size: 1.3em;
    text-transform: uppercase;
    color: red;
    font-weight: bolder;
  }

  .container #serviceBulletinContainer  #railAlertButton #railAlertText .fa {
    float: right;
    padding-right: 10px;
    font-size: 1.2em;
  }

  .container #serviceBulletinContainer #railAlertPanel {
    width: 70%;
    margin: 0 auto 0 auto;
    left: 15%;
    border-radius: 0 0 4px 4px;
    background-color: #fff;
    z-index: 2;
    position: absolute;
  }

  .container #serviceBulletinContainer #railAlertPanel .trainAlertItem {
    border: 1px solid #333;
    font-family: 'Raleway', sans-serif;
    margin-bottom: 9px;
    border-radius: 4px;
    font-size: 1em;
    padding: 6px;
  }

  .container #serviceBulletinContainer #railAlertPanel .trainAlertItem #trainAlertRoute {
    display: inline-block;
    width: 60%;
    
  }


  .container #serviceBulletinContainer #railAlertPanel .trainAlertItem #trainAlertStatus {
    display: inline-block;
    width: 40%;
  }

  .container #serviceBulletinContainer #railAlertPanel .trainAlertItem #alertDescription {
    
  }


  .container #serviceBulletinContainer #stationAlertButton {
    border: 1px solid transparent;
    width: 70%;
    margin: 30px auto 0 auto;
    border-radius: 4px;
    -webkit-box-shadow: 5px 5px 15px -2px #000000; 
    box-shadow: 5px 5px 15px -2px #000000;
    background-color: #fff;
  }

  .container #serviceBulletinContainer  #stationAlertButton #stationAlertText {
    text-align: center;
    font-family: 'Raleway', sans-serif;
    font-size: 1.3em;
    text-transform: uppercase;
    color: green;
    font-weight: bolder;
  }

  .container #serviceBulletinContainer  #stationAlertButton #stationAlertText .fa {
    float: right;
    padding-right: 10px;
    font-size: 1.2em;
  }

  .container #serviceBulletinContainer #stationAlertPanel {
    width: 70%;
    margin: 0 auto 0 auto;
    left: 15%;
    border-radius: 0 0 4px 4px;
    background-color: #fff;
    z-index: 1;
    position: absolute;
  }


  .container #aboutContainer #credits {
    padding-bottom: 90px !important;
  }
}

/*TABLET STYLES */
@media screen and (min-width: 481px) {
  .container {
    height: 100vh;
  }

  .container nav ul {
    width: 70%;
    text-align: center;
    font-size: 1em;
  }

  .container nav ul li {
    width: 50%;
  }

  .container nav #stopsLink {
    width: 30%;
    text-align: center;
  }

  .container nav .overlay {
    height: 100%;
    width: 0;
    position: fixed;
    /* z-index: 1; */
    z-index: 4;
    left: 0;
    top: 0;
    background-color: rgb(138, 183, 212);
    overflow-x: hidden;
    transition: 0.5s;
  }

  

  .container nav .overlay .overlay-content #topOverlay #titleContainer h1 {
    text-align: center;
    font-family: Raleway;
    font-size: 1.6em;

  }

  .container nav .overlay .overlay-content #topOverlay #closeIconContainer {
    /* display: inline-block; */
    float: right;
    margin-right: 30px;
    margin-top: 1px;
  }

  


  .container nav .overlay .overlay-content #topOverlay #closeIconContainer .fa {
    font-size: 2em;
  }

  .container nav .overlay .overlay-content ul {
    color: black;
    padding-top: 20px;
    clear: both;
  }

  .container nav .overlay .overlay-content ul li #directionStyle {
    font-size: 1.6em;
  }

  


  .container nav .overlay .overlay-content #topOverlay #divider {
    height: 1px;
    background-color: #18447b;
    width: 90%; 
    margin: 0 auto;
  }

  .container nav .overlay .overlay-content #topOverlay #removeStopsContainer {
    margin: 20px auto 0 auto;
    text-align: center;
  }

 

  .container nav .overlay .overlay-content #topOverlay #removeStopsContainer #removeText {
    display: inline-block;
    font-family: Raleway;
    font-weight: bolder;
    font-size: 1.6em;

  }

  .container nav .overlay .overlay-content #topOverlay #removeStopsContainer #trashIcon {
    display: inline-block;
    padding-left: 9px;
 }

 .container nav .overlay .overlay-content #topOverlay #removeStopsContainer #trashIcon .fa {
   font-size: 1.6em;
 }

 .container nav .overlay .overlay-content #savedBusList {
  width: 100%;
 }

 .container nav .overlay .overlay-content #savedBusList li {
  margin-top: 5px;
  border-bottom: 1px solid black;
  padding-bottom: 6px;
  width: 100%;
  font-size: 1.3em !important;
 }

 .container nav .overlay .overlay-content #savedBusList li #directionStyle {
   /* padding-left: 24px; */
 }

 .container nav .overlay .overlay-content #savedBusList li .fa.fa-times {
   float: right;
 }

 .container nav .overlay .overlay-content #savedTrainList {
  margin: auto;
  width: 95%;
 }

 .container nav .overlay .overlay-content #savedTrainList li{
   width: 100%;
   border: 1px solid black;
   border-radius: 4px;
   margin-bottom: 9px;
   margin-right: 4px;
   
   padding: 4px;
   color: #fff;
   font-weight: 100;
   font-size: 1.1em;
 }

 .container nav .overlay .overlay-content #savedTrainList li .fa {
   float: right;
 }


 .container #badgeContainer {
  display: block;
  /* background-color: #263237; */
  background-color: #94baf7;
  padding-top: 40px;
  padding-bottom: 40px;
  height: 100vh;
}


.container #badgeContainer  #busBadge {
  border: 1px solid transparent;
  width: 50%;
  margin: 10px auto 0 auto;
  border-radius: 4px;
  -webkit-box-shadow: 5px 5px 15px -2px #000000; 
  box-shadow: 5px 5px 15px -2px #000000;
  background-color: #fff;
}

.container #badgeContainer  #busBadge #busIcon {
  width: 40%;
  display: inline-block;
  padding-left: 8px;
  padding-top: 10px;
}

.container #badgeContainer  #busBadge #busIcon .fa {
  font-size: 5rem;
  display: block;
  margin: 0 auto 20px auto;
  color: #18447b;
}

.container #badgeContainer  #busBadge #busText {
  display: inline-block;
  width: 60%;
  vertical-align: top;
  padding-top: 40px;
  font-size: 1.5em;
  font-family: 'Raleway', sans-serif;
  font-weight: bolder;
  color: #333;

}


.container #badgeContainer  #trainBadge {
  border: 1px solid transparent;
  width: 50%;
  margin: 48px auto 0 auto;
  border-radius: 4px;
  -webkit-box-shadow: 5px 5px 15px -2px #000000; 
  box-shadow: 5px 5px 15px -2px #000000;
  background-color: #fff;
}

.container #badgeContainer  #trainBadge #trainIcon {
  width: 40%;
  display: inline-block;
  padding-left: 8px;
  padding-top: 10px;
}

.container #badgeContainer  #trainBadge #trainIcon .fa {
  font-size: 5rem;
  display: block;
  margin: 0 auto 20px auto;
  color: #263237;
}

.container #badgeContainer  #trainBadge #trainText {
  display: inline-block;
  width: 60%;
  vertical-align: top;
  padding-top: 40px;
  font-size: 1.5em;
  font-family: 'Raleway', sans-serif;
  font-weight: bolder;
  color: #333;
}

.container #badgeContainer #alertBadge {
  border: 1px solid transparent;
  width: 50%;
  margin: 40px auto 0 auto;
  border-radius: 4px;
  -webkit-box-shadow: 5px 5px 15px -2px #000000; 
  box-shadow: 5px 5px 15px -2px #000000;
  background-color: #fff;
}

.container #badgeContainer #alertBadge #alertIcon {
  width: 40%;
  display: inline-block;
  padding-left: 8px;
  padding-top: 10px;
}

.container #badgeContainer #alertBadge #alertIcon .fa {
  font-size: 5rem;
  display: block;
  margin: 0 auto 20px auto;
  color: #263237;
  color: red;
}

.container #badgeContainer #alertBadge #alertText {
  display: inline-block;
  width: 60%;
  vertical-align: top;
  padding-top: 35px;
  font-size: 1.5em;
  font-family: 'Raleway', sans-serif;
  font-weight: bolder;
  color: #333;

}

.container #busRouteContainer {
  padding: 25px 9px 9px 9px;
  background-color: #94baf7;
  
}

.container #busRouteContainer .busRouteItemContainer {
  border: 1px solid transparent;
  border-radius: 4px;
  font-family: 'Raleway', sans-serif;
  font-size: .9em;
  font-weight: bolder;
  width: 70%;
  padding: 9px;
  margin: 0 auto 19px auto;
  background-color: #fff;
  -webkit-box-shadow: 5px 5px 15px -11px #000000; 
  box-shadow: 5px 5px 15px -11px #000000;
}

.container .busRouteItemContainer .busRouteNumber {
  display: inline-block;
  width: 10%;
  text-align: center;
}

.container .busRouteItemContainer .busRouteNumber {
  display: inline-block;
  width: 10%;
  text-align: center;
  font-size: 1.2em;
}

.container .busRouteItemContainer .busRouteName {
  display: inline-block;
  width: 90%;
  font-size: 1.2em;
}


.container #busDirection h1 {
  font-size: 2.3em !important;
}

.container #busDirection div {
  font-size: 2.3em !important;
  padding-bottom: 5px !important;
}

.container #busStopContainer {
  background-color: #94baf7;
}

.container #busStopContainer h1 {
  font-family: Raleway;
  font-size: 1.4em;
  text-align: center;
  color: '#18447b';
  margin: 0;
  padding-top: 7px;
 
}

.container #busStopContainer .busStopItem {
  border: 1px solid transparent;
  border-radius: 4px;
  font-family: 'Raleway', sans-serif;
  font-size: 1.2em;
  font-weight: bolder;
  width: 70%;
  padding: 9px;
  margin: 0 auto 19px auto;
  background-color: #fff;
  -webkit-box-shadow: 5px 5px 15px -11px #000000; 
  box-shadow: 5px 5px 15px -11px #000000;
}

.container #busArrivalContainer {
  padding-top: 16px;
  padding-bottom: 16px;
  background-color: #94baf7;
  height: 100vh;
}

.container #busArrivalContainer .headline {
  font-family: 'Raleway', sans-serif;
  text-align: center;
}

.container #busArrivalContainer .map {
  width: 95%;
  -webkit-box-shadow: 5px 5px 15px -11px #000000; 
  box-shadow: 5px 5px 15px -11px #000000;
  height: 400px;
  margin: auto;
}

.container #busArrivalContainer .busArrivalItem {
  font-family: 'Raleway', sans-serif;
  font-size: 1em;
  margin: 22px auto 35px auto;
  font-weight: bolder;
  /* padding-top: 17px; */
  width: 80%;
  padding: 9px 9px 20px 9px;
  background-color: #fff;
  -webkit-box-shadow: 5px 5px 15px -11px #000000; 
  box-shadow: 5px 5px 15px -11px #000000;



}

.container #busArrivalContainer .busArrivalItem #arrivalTime {
  text-align: center;
  font-size: 2.2em;
  margin-bottom: 5px;
}

.container #busArrivalContainer .busArrivalItem #arrivalDestination {
  font-size: 1.5em;
}

.container #busArrivalContainer .busArrivalItem #arrivalNumber {
  font-size: 1.5em;
}

.container #busArrivalContainer .busArrivalItem #arrivalHeading {
  font-size: 1.5em;
}

.container #busArrivalContainer .busArrivalItem #arrivalFeet {
  font-size: 1.5em;
}

.container #trainLinesContainer {
  border: 1px solid transparent;
  background-color: #94baf7;
  height: 100vh;
}

.container #trainLinesContainer .trainButton {
  width: 80%;
  padding: 9px;
  background-color: #fff;
  -webkit-box-shadow: 5px 5px 15px -11px #000000; 
  box-shadow: 5px 5px 15px -11px #000000;
  margin: 29px auto 23px auto;
  text-align: center;
  font-size: 1.3em;
  font-family: 'Raleway', sans-serif;
  border-radius: 8px;
  color: #fff;
}

.container #trainLinesContainer #blueLineContainer {
  background-color: blue;
}

.container #trainLinesContainer #redLineContainer {
  background-color: red;
}

.container #trainLinesContainer #brownLineContainer {
  background-color: brown;
}

.container #trainLinesContainer #greenLineContainer {
  background-color: green;
}

.container #trainLinesContainer #orangeLineContainer {
  background-color: darkorange;
}

.container #trainLinesContainer #purpleLineContainer {
  background-color: purple;
}

.container #trainLinesContainer #pinkLineContainer {
  background-color: hotpink;
}

.container #trainLinesContainer #yellowLineContainer {
  background-color: yellow;
}

.container #trainStopsContainer {
  border: 1px solid transparent;
}



.container #trainStopsContainer .stationName{
  width: 80%;
  padding: 9px;
  background-color: #fff;
  /* -webkit-box-shadow: 5px 5px 15px -11px #000000; 
  box-shadow: 5px 5px 15px -11px #000000; */
  margin: 29px auto 23px auto;
  text-align: center;
  font-size: 1.3em;
  font-weight: bolder;
  font-family: 'Raleway', sans-serif;
  border-radius: 8px;
  color: #333;
}

.container #trainDestinationContainer {
  border: 1px solid transparent;
  height: 100vh;
}

.container #trainDestinationContainer h2 {
  text-align: center;
  font-family: 'Raleway', sans-serif;
  
}

.container #trainDestinationContainer .trainDestinationItem {
  width: 80%;
  padding: 9px;
  background-color: #fff;
  /* -webkit-box-shadow: 5px 5px 15px -11px #000000; 
  box-shadow: 5px 5px 15px -11px #000000; */
  margin: 29px auto 23px auto;
  text-align: center;
  font-size: 1.3em;
  font-weight: bolder;
  font-family: 'Raleway', sans-serif;
  border-radius: 8px;
  color: #333;
}

.container #serviceBulletinContainer {
  padding-top: 40px;
  position: relative;
}

.container #serviceBulletinContainer #busAlertButton {
  border: 1px solid transparent;
  width: 70%;
  margin: 10px auto 0 auto;
  border-radius: 4px;
  -webkit-box-shadow: 5px 5px 15px -2px #000000; 
  box-shadow: 5px 5px 15px -2px #000000;
  background-color: #fff;
}

.container #serviceBulletinContainer  #busAlertButton #busAlertText {
  font-family: 'Raleway', sans-serif;
  font-size: 1.3em;
  text-align: center;
  text-transform: uppercase;
  color: blue;
  font-weight: bolder;
}

.container #serviceBulletinContainer #busAlertButton #busAlertText .fa {
  float: right;
  padding-right: 10px;
  font-size: 1.2em;
}

.container #serviceBulletinContainer #busAlertPanel {
  width: 70%;
  margin: 0 auto 0 auto;
  left: 15%;
  border-radius: 0 0 4px 4px;
  background-color: #fff;
  z-index: 3;
  position: absolute;
}

.container #serviceBulletinContainer #busAlertPanel .busAlertItem {
  border: 1px solid #333;
  font-family: 'Raleway', sans-serif;
  margin-bottom: 9px;
  border-radius: 4px;
  font-size: 1em;
}


.container #serviceBulletinContainer #busAlertPanel .busAlertItem #busAlertRoute {
  display: inline-block;
  width: 50%
}

.container #serviceBulletinContainer #busAlertPanel .busAlertItem #busAlertStatus {
  display: inline-block;
  width: 50%;
  color: red;
  text-transform: uppercase;
}

.container #serviceBulletinContainer #busAlertPanel .busAlertItem #alertDescription {
  display: inline-block;
  border: 1px solid black;
}

.container #serviceBulletinContainer #busAlertPanel .busAlertItem #closeDescription {
  display: inline-block;
  border: 1px solid black;

}



.container #serviceBulletinContainer #railAlertButton {
  border: 1px solid transparent;
  width: 70%;
  margin: 30px auto 0 auto;
  border-radius: 4px;
  -webkit-box-shadow: 5px 5px 15px -2px #000000; 
  box-shadow: 5px 5px 15px -2px #000000;
  background-color: #fff;
  z-index: 2;
  /* position: absolute; */
}

.container #serviceBulletinContainer  #railAlertButton #railAlertText {
  text-align: center;
  font-family: 'Raleway', sans-serif;
  font-size: 1.3em;
  text-transform: uppercase;
  color: red;
  font-weight: bolder;
}

.container #serviceBulletinContainer  #railAlertButton #railAlertText .fa {
  float: right;
  padding-right: 10px;
  font-size: 1.2em;
}

.container #serviceBulletinContainer #railAlertPanel {
  width: 70%;
  margin: 0 auto 0 auto;
  left: 15%;
  border-radius: 0 0 4px 4px;
  background-color: #fff;
  z-index: 2;
  position: absolute;
}

.container #serviceBulletinContainer #railAlertPanel .trainAlertItem {
  border: 1px solid #333;
  font-family: 'Raleway', sans-serif;
  margin-bottom: 9px;
  border-radius: 4px;
  font-size: 1em;
  padding: 6px;
}

.container #serviceBulletinContainer #railAlertPanel .trainAlertItem #trainAlertRoute {
  display: inline-block;
  width: 60%;
  
}


.container #serviceBulletinContainer #railAlertPanel .trainAlertItem #trainAlertStatus {
  display: inline-block;
  width: 40%;
}

.container #serviceBulletinContainer #railAlertPanel .trainAlertItem #alertDescription {
  
}


.container #serviceBulletinContainer #stationAlertButton {
  border: 1px solid transparent;
  width: 70%;
  margin: 30px auto 0 auto;
  border-radius: 4px;
  -webkit-box-shadow: 5px 5px 15px -2px #000000; 
  box-shadow: 5px 5px 15px -2px #000000;
  background-color: #fff;
}

.container #serviceBulletinContainer  #stationAlertButton #stationAlertText {
  text-align: center;
  font-family: 'Raleway', sans-serif;
  font-size: 1.3em;
  text-transform: uppercase;
  color: green;
  font-weight: bolder;
}

.container #serviceBulletinContainer  #stationAlertButton #stationAlertText .fa {
  float: right;
  padding-right: 10px;
  font-size: 1.2em;
}

.container #serviceBulletinContainer #stationAlertPanel {
  width: 70%;
  margin: 0 auto 0 auto;
  left: 15%;
  border-radius: 0 0 4px 4px;
  background-color: #fff;
  z-index: 1;
  position: absolute;
}

.container #aboutContainer {
  font-family: Raleway;
  background: #94baf7;
}

.container #aboutTitle {
  text-align: center;
  font-size: 2em;
  padding-top: 30px;
}

.container #logo {
  padding-top: 30px;
}

.container #logo img {
  margin: auto;
}

.container #axiosLogo {
  padding-top: 40px;
  padding-bottom: 70px;
}

.container #axiosLogo img {
  margin: auto;
}

.container #aboutContainer #credits {
  padding-bottom: 60px !important;
  font-size: 1.9em !important;
}

.container #trainArrivalContainer #refreshTrains {
  text-align: center;
  font-size: 2.5em;
}

.container #trainArrivalContainer .trainArrivalItem {
  width: 80%;
  padding: 9px;
  background-color: #fff;
  /* -webkit-box-shadow: 5px 5px 15px -11px #000000; 
  box-shadow: 5px 5px 15px -11px #000000; */
  margin: 29px auto 23px auto;
  /* text-align: center; */
  font-size: 1.7em;
  font-weight: bolder;
  font-family: 'Raleway', sans-serif;
  border-radius: 8px;
  color: #333;
  
}






  

}

